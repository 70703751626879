import React from 'react';
import useHotelDetail from './HotelDetail.hook';
import DefaultLayout from '../../../DefaultLayout/DefaultLayout';
import Loading from '../../../common/Loader/Loading';
import styles from './HotelDetail.module.css';
import { Button, Input, Form, Row, Col, Checkbox } from 'antd';
import FormText from '../../../common/form-items/FormText';
import { Link } from 'react-router-dom';
import SelectInput from '../../../common/SelectInput/SelectInput';
import constants from '../../../../utils/constants';

export default function HotelDetail() {
  const { hotelState, hotelActions } = useHotelDetail();

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  const { data, pmsApiConfig, operaConnectionConfig, operaDataConfig, configCheckin } = hotelState || {};

  return (
    <DefaultLayout>
      <div className={styles.container}>
        {hotelState.loading && <Loading />}
        {hotelState.data && (
          <div className={styles.form}>
            <Row justify={'end'}>
              <Col>
                <Link to={`/hotel/${data.id}/formFields`}>
                  <Button>Ver/Editar campos de formulario</Button>
                </Link>
              </Col>
            </Row>
            <Form
              {...layout}
              name="hotel"
              initialValues={{
                ...data,
                ...operaConnectionConfig,
                ...operaDataConfig,
                ...pmsApiConfig,
              }}
              onFinish={values => {
                hotelActions.patchHotelData();
              }}
            >
              <Form.Item label="ID" name="id">
                <FormText>{data.id}</FormText>
              </Form.Item>

              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please input hotel name!',
                  },
                ]}
              >
                <Input
                  onChange={e => {
                    hotelActions.updateData({ name: e.target.value });
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Slug"
                name="slug"
                rules={[
                  {
                    required: true,
                    message: 'Please input hotel slug!',
                  },
                ]}
              >
                <Input
                  onChange={e => {
                    hotelActions.updateData({ slug: e.target.value });
                  }}
                />
              </Form.Item>

              <SelectInput
                label="State"
                name="state"
                values={['active', 'disabled']}
                currentValue={data.state}
                onChange={e => {
                  hotelActions.updateData({ state: e });
                }}
              />

              <Form.Item label="PMS Key" name="pmsKey">
                <Input
                  onChange={e => {
                    hotelActions.updateData({ pmsKey: e.target.value });
                  }}
                />
              </Form.Item>

              <SelectInput
                label="PMS Format"
                name="pmsFormat"
                values={constants.PMS_FORMAT}
                currentValue={data.pmsFormat}
                onChange={e => {
                  hotelActions.updateData({ pmsFormat: e });
                }}
              />

              <Form.Item label="Custom PMS Id" name="customPmsId">
                <Input
                  onChange={e => {
                    hotelActions.updateData({ customPmsId: e.target.value });
                  }}
                />
              </Form.Item>

              <Form.Item label="Hotel Premium" name="premiumHotel">
                <Checkbox
                  onChange={e => {
                    hotelActions.updateConfigCheckin({ premium: !!e.target.checked });
                  }}
                  checked={!!configCheckin.premium}
                />
              </Form.Item>

              {/* PMS API CONFIG */}
              {[
                <Form.Item {...tailLayout}>
                  <h3 className={styles.separator}>PMS api config</h3>
                </Form.Item>,
                <SelectInput
                  label="checkin module"
                  name="checkin_module"
                  values={constants.CHECKIN_MODULE}
                  currentValue={pmsApiConfig.checkin_module}
                  onChange={e => {
                    hotelActions.updatePmsApiConfig({ checkin_module: e });
                  }}
                />,
                Object.keys(pmsApiConfig).map(key => {
                  if (key != 'id' && key != 'hotelId' && key != 'checkin_module') {
                    return (
                      <Form.Item key={key} label={key} name={key}>
                        <Input
                          onChange={e => {
                            hotelActions.updatePmsApiConfig({ [key]: e.target.value });
                          }}
                        />
                      </Form.Item>
                    );
                  } else return null;
                }),
              ]}

              {/* OPERA CONNECTION CONFIG */}
              {pmsApiConfig.checkin_module.toLowerCase() === 'opera' && [
                <Form.Item {...tailLayout}>
                  <h3 className={styles.separator}>Opera connection config</h3>
                </Form.Item>,
                Object.keys(operaConnectionConfig).map(key => {
                  const ignoreKeys = ['id', 'hotelId', 'cloud'];
                  if (ignoreKeys.indexOf(key) === -1) {
                    return (
                      <Form.Item key={key} label={key} name={key}>
                        <Input
                          onChange={e => {
                            hotelActions.updateOperaConnectionConfig({ [key]: e.target.value });
                          }}
                        />
                      </Form.Item>
                    );
                  } else return null;
                }),
                <SelectInput
                  label="cloud"
                  name="cloud"
                  values={[0, 1]}
                  currentValue={operaConnectionConfig.cloud}
                  onChange={e => {
                    hotelActions.updateOperaConnectionConfig({ cloud: e });
                  }}
                />,
              ]}

              {/* OPERA DATA CONFIG */}
              {pmsApiConfig.checkin_module.toLowerCase() === 'opera' && [
                <Form.Item {...tailLayout}>
                  <h3 className={styles.separator}>Opera data config</h3>
                </Form.Item>,
                Object.keys(operaDataConfig).map(key => {
                  if (key != 'id' && key != 'hotelId') {
                    return (
                      <Form.Item key={key} label={key} name={key}>
                        <Input
                          onChange={e => {
                            hotelActions.updateOperaDataConfig({ [key]: e.target.value });
                          }}
                        />
                      </Form.Item>
                    );
                  } else return null;
                }),
              ]}

              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  Guardar
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </DefaultLayout>
  );
}
