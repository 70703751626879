import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useApi from '../../../../utils/networking/Api.hook';

export default function useHotelDetail() {
  let { id } = useParams();
  const { API } = useApi();

  const [hotelState, setState] = useState({
    data: null,
    pmsApiConfig: {
      hotelId: id,
      checkin_module: '',
      checkin_module_url: '',
      checkin_booking_url: '',
      checkin_confirm_url: '',
      checkin_data_url: '',
      checkin_bookings_of_day_url: '',
      checkin_file_url: '',
      checkin_module_http_user: '',
      checkin_module_http_password: '',
      adults_code: '',
      children_code: '',
      checkin_payment_balance_url: '',
      checkin_payment_register_url: '',
      checkin_find_booking_url: '',
    },
    operaDataConfig: {
      hotelId: id,
      passportCode: '',
      dniCode: '',
      idCardCode: '',
      creditCardCode: '',
      currencyCode: '',
      nationalityCode: '',
      countryCode: '',
    },
    operaConnectionConfig: {
      hotelId: id,
      originEntitiyId: '',
      originSystemType: '',
      destinationEntityId: '',
      destinationSystemType: '',
      userName: '',
      userPassword: '',
      domain: '',
      hotelCode: '',
      chainCode: '',
      cloud: '',
      cloudUserToken: '',
      cloudUserName: '',
      cloudUserPassword: '',
      registerPaymentWs: '',
    },
    configCheckin: {
      premium: 0,
    },
    loading: false,
  });
  useEffect(() => {
    hotelActions.loadData();
  }, []);

  const hotelActions = {
    loadData: () => {
      hotelActions.setLoading(true);

      const include = JSON.stringify([
        'PmsApiConfig',
        'OperaDataConfig',
        'OperaConnectionConfig',
        {
          model: 'AppSection',
          where: { type: 'checkin' },
          attributes: ['id'],
          include: [{ model: 'ConfigCheckin', attributes: ['id', 'premium'] }],
        },
      ]);
      return API.hotel(id, include).then(data => {
        const { pmsApiConfig, operaDataConfig, operaConnectionConfig } = data;
        const configCheckin = data.appSections[0]?.configCheckin;

        const state = {};
        if (pmsApiConfig) {
          delete data.pmsApiConfig;
          state.pmsApiConfig = pmsApiConfig;
        }
        if (operaDataConfig) {
          delete data.operaDataConfig;
          state.operaDataConfig = operaDataConfig;
        }
        if (operaConnectionConfig) {
          delete data.operaConnectionConfig;
          state.operaConnectionConfig = operaConnectionConfig;
        }
        if (configCheckin) {
          delete data.appSections;
          state.configCheckin = configCheckin;
        }
        state.data = data;
        updateState(state);
        hotelActions.setLoading(false);
      });
    },
    patchHotelData: () => {
      const { id, name, pmsKey, pmsFormat, customPmsId, slug, state } = hotelState.data;
      const hotelData = {
        name,
        pmsKey,
        pmsFormat,
        customPmsId,
        slug,
        state,
      };
      if (hotelState.pmsApiConfig) hotelData.pmsApiConfig = hotelState.pmsApiConfig;
      if (
        hotelState.pmsApiConfig &&
        hotelState.pmsApiConfig.checkin_module.toLowerCase() === 'opera'
      ) {
        if (hotelState.operaDataConfig) hotelData.operaDataConfig = hotelState.operaDataConfig;
        if (hotelState.operaConnectionConfig)
          hotelData.operaConnectionConfig = hotelState.operaConnectionConfig;
      }

      hotelActions.setLoading(true);
      Promise.all([
        API.hotelPatch(id, hotelData),
        API.configCheckinPatch(hotelState.configCheckin.id, hotelState.configCheckin),
      ]).then(res => {
        hotelActions.loadData();
        hotelActions.setLoading(false);
      });
    },
    updateData: values => {
      const data = { ...(hotelState.data || {}), ...values };
      updateState({ data });
    },
    updatePmsApiConfig: values => {
      const pmsApiConfig = { ...(hotelState.pmsApiConfig || {}), ...values };
      updateState({ pmsApiConfig });
    },
    updateOperaConnectionConfig: values => {
      const operaConnectionConfig = { ...(hotelState.operaConnectionConfig || {}), ...values };
      updateState({
        operaConnectionConfig,
      });
    },
    updateOperaDataConfig: values => {
      const operaDataConfig = { ...(hotelState.operaDataConfig || {}), ...values };
      updateState({
        operaDataConfig,
      });
    },
    updateConfigCheckin: values => {
      const configCheckin = { ...(hotelState.configCheckin || {}), ...values };
      updateState({ configCheckin });
    },
    setLoading: loading => {
      updateState({ loading });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { hotelState, hotelActions };
}
